import React from "react";
import shortid from "shortid";
import { Row, Col, Button } from "react-bootstrap";
import SkillLevelSelector from "../components/SkillLevelSelector.js";
import SkillLevelDetail from "../components/SkillLevelDetail.js";

const SkillLane = ({
  area,
  skills,
  setSelectedSkill,
  setSelectedLevelDetails,
  skillValues,
  selectedSkill,
  selectedLevelDetails,
  setSkillValues
}) => {
  let applicableSkills = Object.values(skills);
  let skillLevelDetails = applicableSkills.find(skill => skill.uuid === selectedSkill.uuid)

  return (
  <div className="skill-lane">
    <h2>{area}</h2>
    <Row>
      {skills &&
        Object.entries(skills).map(([skillName, sk]) => (
          <Col xs={2} className="text-center text-wrap" key={shortid.generate()}>
            <div className={`skill ${sk.uuid === selectedSkill.uuid ? "active" : ""}`}>
              <p className="small">{skillName}</p>
              <Button
                onClick={() => {
                  let setShowValue = undefined
                  if (skillValues && skillLevelDetails && skillValues[sk.uuid]) {
                    setShowValue = `E${skillValues[sk.uuid]}`
                  }

                  setSelectedSkill({
                    ...sk,
                    name: skillName,
                  });

                  if (setShowValue) {
                    return setSelectedLevelDetails({
                      ...skillLevelDetails[setShowValue],
                      name: setShowValue
                    });
                  }
                  
                  setSelectedLevelDetails(); // clear out details
                }}
                variant={
                  skillValues && skillValues[sk.uuid] ? "primary" : "light"
                }
              >
                {(skillValues && skillValues[sk.uuid]) || "?"}
              </Button>
            </div>
          </Col>
        ))}
    </Row>
    {skillLevelDetails  && (
        <div className="rating-container" key={shortid.generate()}>
          <SkillLevelSelector
            selectedSkill={selectedSkill}
            selectedLevelDetails={selectedLevelDetails}
            setSelectedLevelDetails={setSelectedLevelDetails}
            skillValues={skillValues}
            setSkillValues={setSkillValues}
          />
          <SkillLevelDetail
            selectedLevelDetails={selectedLevelDetails}
            selectedSkill={selectedSkill}
          />
        </div>
      )}
  </div>
)
};

export default SkillLane;
