import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FaFileExport } from "react-icons/fa";
const _ = require("lodash");

const ExportButton = ({ skillValues, framework }) => {
  const [exportValue, setExportValue] = useState("");
  if (!skillValues)
    return null // don't display if empty
  return (
    <>
      <Button
        size="lg"
        block
        variant="dark"
        onClick={() => {
          const out = _.flattenDeep(
            Object.entries(framework.areas).map((skills) =>
              Object.entries(skills[1]).map(
                (x) => skillValues[x[1].uuid] || "?"
              )
            )
          );

          setExportValue(out);
        }}
      >
        <FaFileExport /> Export
      </Button>
      {exportValue !== "" && (
        <Form.Control size="lg" type="text" value={exportValue} />
      )}
    </>
  );
};

export default ExportButton;
