import React from "react";
import shortid from "shortid";
import { Row, Col, Button } from "react-bootstrap";

const SkillLevelSelector = ({
  selectedSkill,
  selectedLevelDetails,
  setSelectedLevelDetails,
  skillValues,
  setSkillValues,
}) => {
  return (
    <>
      <Row>
        <Col>
          <h3>{selectedSkill.name}</h3>
        </Col>
      </Row>
      <Row className="rating-buttons">
      {[...Array(5)].map((_, level) => (
        <Col key={shortid.generate()}>
          <Button
            key={level}
            size="small"
            variant={
              selectedLevelDetails &&
              selectedLevelDetails.name === `E${level + 1}`
                ? "primary rating"
                : "light rating"
            }
            onClick={() => {
              
              const nv = {};
              nv[selectedSkill.uuid] = level + 1;
              setSkillValues({
                ...skillValues,
                ...nv,
              });

              const whichSkill = Object.entries(selectedSkill).filter(
                ([name, v]) => {
                  return name === `E${level + 1}`;
                }
              )[0][1];

              setSelectedLevelDetails({
                ...whichSkill,
                name: `E${level + 1}`,
              });
            }}
          >
            {level + 1}
          </Button>
        </Col>
      ))}
        <Col>
          <Button
            variant="light reset"
            onClick={() => {
              const nullValue = {};
              nullValue[selectedSkill.uuid] = null;
              setSkillValues({
                ...skillValues,
                ...nullValue,
              });
              setSelectedLevelDetails(nullValue);
            }}
          >
            Reset
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default SkillLevelSelector;
