import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaHandPointUp } from "react-icons/fa";

const SkillLevelDetail = ({ selectedSkill, selectedLevelDetails }) => {
  if (selectedLevelDetails == null || selectedLevelDetails.name == null)
    return <span className="placeholder"><FaHandPointUp /> Select a skill level to get started</span>;

  return (
    <>
      <Row>
        <Col>
          <h3>
            Level {selectedLevelDetails.name}
          </h3>
          <p>
            {"An " +
              selectedLevelDetails.name +
              " engineer " +
              selectedLevelDetails.description}
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
            <p>
              <b>Example behaviours:</b>
            </p>
            <ul>
              {selectedLevelDetails.behaviours.map((b) => (
                <li>{b}</li>
              ))}
              {selectedLevelDetails.behaviours.length === 0 && (
                <li className="small text-muted">
                  <a href="https://github.com/flypay/engineering-progression-framework" target="_blank" rel="noopener noreferrer">
                    None yet. Contribute your own?
                  </a>
                </li>
              )}
            </ul>
        </Col>
        <Col>
            <p>
              <b>Example work:</b>
            </p>
            <ul>
              {selectedLevelDetails.tasks.map((t) => (
                <li>{t}</li>
              ))}
              {selectedLevelDetails.tasks.length === 0 && (
                <li className="small text-muted">
                  <a href="https://github.com/flypay/engineering-progression-framework" target="_blank" rel="noopener noreferrer">
                    None yet. Contribute your own?
                  </a>
                </li>
              )}
            </ul>
        </Col>
      </Row>
    </>
  );
};

export default SkillLevelDetail;
