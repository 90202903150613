import React from "react";

const Title = () => (
  <h1>
    <span role="img" alt="snowflake" aria-label="snowflake">
      ❄️
    </span>{" "}
    SnowFlyt
  </h1>
);

export default Title;
