import React, { useState } from "react";
import Title from "./components/Title.js";
import Person from "./components/Person.js";
import CopyToClipboardButton from "./components/CopyToClipboardButton.js";
import SkillLane from "./components/SkillLane.js";

import { Container, Row, Col } from "react-bootstrap";
import "./App.css";
import ExportButton from "./components/ExportButton.js";
const moment = require("moment");

// Constants
const framework = require("./progression-framework/progression-framework.json");
const defaultSkill = framework.areas["Managing yourself"]["Focus & Prioritization"];
const defaultSkillLevel = "E1";
const defaultURLHash = {
  baseUrl: window.location.origin,
  copied: false,
};

// App
const App = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlPerson = urlParams.get("for") || "";
  const urlReportDate = urlParams.get("created_on");
  let urlData = {};
  try {
    urlData = JSON.parse(decodeURIComponent(urlParams.get("data")));
  } catch {
    // no action, use default
  }

  const [selectedSkill, setSelectedSkill] = useState({...defaultSkill, name: "Focus & Prioritization"});
  const [person, setPerson] = useState(urlPerson);
  const [reportDate, setReportDate] = useState(urlReportDate);
  const [skillValues, setSkillValues] = useState(urlData);
  const [urlHash, setUrlHash] = useState(defaultURLHash);
  const [selectedLevelDetails, setSelectedLevelDetails] = useState(
    defaultSkill[defaultSkillLevel]
  );

  return (
    <Container>
      <Title />
      <Row>
        <Col>
          <Person setPerson={setPerson} person={person} />
        </Col>
        <Col xs={4}>
          <CopyToClipboardButton
            setUrlHash={setUrlHash}
            urlHash={urlHash}
            skillValues={skillValues}
            person={person}
            setReportDate={setReportDate}
            reportDate={reportDate}
          />
        </Col>
      </Row>
      {reportDate != null && (
        <div className="highlight-summary">
          <p>
            Report generated on{" "}
            {moment.unix(reportDate).format("MMMM Do YYYY, HH:mm")}
          </p>
          <ExportButton skillValues={skillValues} framework={framework} />
        </div>
      )}
      {Object.entries(framework.areas).map(([area, skills]) => (
        <SkillLane
          key={area}
          area={area}
          skills={skills}
          setSelectedSkill={setSelectedSkill}
          setSelectedLevelDetails={setSelectedLevelDetails}
          skillValues={skillValues}
          selectedSkill={selectedSkill}
          selectedLevelDetails={selectedLevelDetails}
          setSkillValues={setSkillValues}
        />
      ))}
    </Container>
  );
};

export default App;
